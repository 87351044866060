import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createConsumer } from '@rails/actioncable';

import { SERVER_URL } from '../environment';
import { setNewMessageState } from '../actions/misc';
import { fetchTodayAppointments } from '../ducks/dashboard/todayAppointments';
import { fetchAppointment } from '../actions/sessions';

class NotificationManager extends Component {
  constructor() {
    super();
    this.cable = null;
    this.channel = null;
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.subscribe();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.authenticated !== prevProps.authenticated) {
      if (this.props.authenticated) {
        this.subscribe();
      } else {
        this.unsubscribe();
      }
    }
  }

  subscribe() {
    const token = localStorage.getItem('token');

    if (token) {
      this.cable = createConsumer(`${SERVER_URL}/cable?token=${token}`);
      this.channel = this.cable.subscriptions.create(
        { channel: 'WebNotificationChannel' },
        {
          received: this.handleReceived,
          initialized: () => {},
          connected: () => {},
          rejected: () => {
            if (this.cable) this.cable.disconnect();
          },
          disconnected: () => {},
        },
      );
    }
  }

  unsubscribe() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }

    if (this.cable) {
      this.cable.disconnect();
      this.cable = null;
    }
  }

  handleReceived = notification => {
    const currentPath = this.props.location.pathname;
    if (notification.type === 'new_chat_message') {
      if (/^\/messages/.test(currentPath)) {
        if (this.props.newMessageHandler) {
          this.props.newMessageHandler(notification);
        }
      } else {
        this.props.setNewMessageState(true);
      }
    } else if (notification.type === 'reschedule_appointment') {
      if (/^\/dashboard/.test(currentPath) || currentPath === '/') {
        this.props.fetchTodayAppointments();
      }
    } else if (notification.type === 'audio_conference') {
      if (
        /^\/new_video.+$/.test(currentPath) ||
        /^\/sessions.+video_call$/.test(currentPath)
      ) {
        if (notification.body && notification.body.appointment_id) {
          this.props.fetchAppointment(notification.body.appointment_id);
        }
      }
    }
  };

  render() {
    return <noscript />;
  }
}

NotificationManager.propTypes = {
  location: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  setNewMessageState: PropTypes.func.isRequired,
  fetchTodayAppointments: PropTypes.func.isRequired,
  fetchAppointment: PropTypes.func.isRequired,
  newMessageHandler: PropTypes.func,
};

NotificationManager.defaultProps = {
  newMessageHandler: null,
};

function mapStateToProps(state) {
  const { authenticated } = state.auth;
  const { newMessageHandler } = state.misc;

  return {
    authenticated,
    newMessageHandler,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    setNewMessageState,
    fetchTodayAppointments,
    fetchAppointment,
  })(NotificationManager),
);
